<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Minhas vendas</h1>
        <!--end::Title-->
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative"></div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Filters-->
    <div class="d-flex flex-column flex-lg-row pb-2 gap-3 align-items-center me-lg-20">
      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-semibold">Período:</label>
        <!--end::Label-->
        <!--begin::Input-->
        <div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex flex-column flex-md-row align-items-center gap-1">
              <input class="form-control bg-white date_picker_date" v-model.trim="filters.start_date" />
              <div class="">
                <button type="button" class="btn btn-sm btn-primary py-3" @click="filters.start_date = ''" v-if="filters.start_date" v-b-tooltip.hover title="Limpar"><i class="fa-solid fa-filter-circle-xmark"></i></button>
              </div>
            </div>
            <div class="px-4">-</div>
            <div class="d-flex flex-column flex-md-row align-items-center gap-1">
              <input class="form-control bg-white date_picker_date" v-model.trim="filters.end_date" />
              <div class="">
                <button type="button" class="btn btn-sm btn-primary py-3" @click="filters.end_date = ''" v-if="filters.end_date" v-b-tooltip.hover title="Limpar"><i class="fa-solid fa-filter-circle-xmark"></i></button>
              </div>
            </div>
          </div>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->
      <!--begin::Actions-->
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-sm btn-primary mt-3 py-3" data-kt-menu-dismiss="true" @click="applyFilters" v-b-tooltip.hover title="Filtrar"><i class="fa-solid fa-filter"></i></button>
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Filters-->
    <div>
      <div v-if="loader" class="text-center">
        <Loader
          :size="'big'"
        />
      </div>
      <SalesSummary
        :data="summary"
        v-else-if="summary"
      />
      <div class="mt-10">
        <!--begin::Products-->
        <div class="card card-flush">
          <!--begin::Card body-->
          <div class="card-body pt-0">
            <template v-if="loader && claims.length === 0">
              <div class="py-5">
                <Loader size="big" />
              </div>
            </template>
            <template v-else-if="!loader && claims.length === 0">
              <p class="text-center pt-5">Nenhum pagamento encontrado</p>
            </template>
            <template v-else>
              <div class="table-responsive">
                <!--begin::Table-->
                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
                  <!--begin::Table head-->
                  <thead>
                    <!--begin::Table row-->
                    <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                      <th class="min-w-70px">Empresa</th>
                      <th class="min-w-70px">Anunciante</th>
                      <th class="min-w-70px">Número pagamento</th>
                      <th class="min-w-70px">Número PI</th>
                      <th class="min-w-70px">Valor da venda</th>
                      <th class="min-w-70px">Prazo de pagamento</th>
                      <th class="min-w-70px">Status do pagamento</th>
                      <th class="min-w-70px">Valor comissão</th>
                      <th class="min-w-70px">Pagamento comissão</th>
                    </tr>
                    <!--end::Table row-->
                  </thead>
                  <!--end::Table head-->
                  <!--begin::Table body-->
                  <tbody class="fw-semibold text-gray-600">
                    <!--begin::Table row-->
                    <tr v-for="(claim, index) in claims" :key="index">
                      <td>
                        <span>
                          <router-link
                            :to="{ name: 'companies.show', params: { id: claim.order.company.id } }"
                          >
                            {{ claim.order.company.name }}
                          </router-link>
                        </span>
                      </td>
                      <td>
                        <span>
                          <router-link
                            :to="{ name: 'advertisers.show', params: { id: claim.order.advertiser.id } }"
                          >
                            {{ claim.order.advertiser.name }}
                          </router-link>
                        </span>
                      </td>
                      <td>
                        <span>
                          <a
                            href="javascript(0);"
                            @click.prevent="$bvModal.show(`order-detail-modal-${claim.order.id}`)"
                          >
                            {{ claim.order.identifier }}
                          </a>
                        </span>
                        <OrderDetailModal
                          :orderId="claim.order.id"
                        />
                      </td>
                      <td>
                        <span v-if="claim.order.insertion_order">
                          <router-link
                            :to="{ name: 'insertion.orders.show', params: { id: claim.order.insertion_order.id } }"
                            target="_blank"
                          >
                            {{ claim.order.insertion_order.identifier }}
                          </router-link>
                        </span>
                      </td>
                      <td>
                        <span>{{ claim.order.amount / 100 | formatMoney }}</span>
                      </td>
                      <td>
                        <span>{{ claim.order.payment_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                      </td>
                      <td>
                        <span>{{ claim.order.payment_status | formatPaymentStatus }}</span>
                      </td>
                      <td>
                        <span>{{ claim.commission_amount/100 | formatMoney }}</span>
                      </td>
                      <td>
                        <span>{{ claim.payment_status == 'PAID' ? 'Pago' : 'Pendente pagamento' }}</span>
                      </td>
                    </tr>
                    <!--end::Table row-->
                  </tbody>
                  <!--end::Table body-->
                </table>
              </div>
              <!--end::Table-->
            </template>
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Products-->
      </div>
    </div>
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import OrderClaimsService from '@/modules/order-claims/services/order-claims-service'
import moment from 'moment'

import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'
import OrderDetailModal from '@/components/orders/OrderDetailModal'
import SalesSummary from '@/components/order-claims/SalesSummary'

export default {
  components: {
    Loader,
    MainLayout,
    OrderDetailModal,
    SalesSummary
  },
  metaInfo () {
    return {
      title: 'Minhas vendas'
    }
  },
  data () {
    return {
      claims: [],
      filters: {
        start_date: '',
        end_date: ''
      },
      summary: [],
      loader: true,
      isFiltersOpen: false
    }
  },
  created () {
    this.filters.start_date = moment({ hour: 0, minute: 0, seconds: 0 }).subtract(3, 'months').startOf('month').format('L')
    this.filters.end_date = moment({ hour: 0, minute: 0, seconds: 0 }).endOf('month').format('L')
    this.getSalesSummary()
  },
  mounted () {
    document.getElementsByClassName('date_picker_date').forEach(item => {
      item.flatpickr({
        dateFormat: 'd/m/Y'
      })
    })
  },
  methods: {
    /**
     * Apply filters and search
     */
    applyFilters () {
      this.isFiltersOpen = false
      this.claims = []
      this.getSalesSummary()
    },
    /**
     * Get sales summary
     */
    async getSalesSummary () {
      this.loader = true
      try {
        const data = await OrderClaimsService.getSalesSummary({
          startDate: this.filters.start_date,
          endDate: this.filters.end_date
        })
        this.summary = data.summary
        this.claims = data.claims.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
