<template>
  <div class="d-flex flex-column flex-lg-row row">
    <div class="col-12 col-lg-3">
      <!--begin::Card widget 2-->
      <div class="card h-lg-100">
        <!--begin::Body-->
        <div class="card-body d-flex justify-content-center align-items-start flex-column">
          <!--begin::Section-->
          <div class="d-flex flex-column my-7">
            <!--begin::Number-->
            <span class="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{{ data.sales_amount / 100 | formatMoney }}</span>
            <!--end::Number-->
            <!--begin::Follower-->
            <div class="m-0">
              <span class="fw-semibold fs-6 text-gray-400">Total em vendas</span>
            </div>
            <!--end::Follower-->
          </div>
          <!--end::Section-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Card widget 2-->
    </div>
    <div class="col-12 col-lg-3">
      <!--begin::Card widget 2-->
      <div class="card h-lg-100">
        <!--begin::Body-->
        <div class="card-body d-flex justify-content-center align-items-start flex-column">
          <!--begin::Section-->
          <div class="d-flex flex-column my-7">
            <!--begin::Number-->
            <span class="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{{ data.paid_sales_amount / 100 | formatMoney }}</span>
            <!--end::Number-->
            <!--begin::Follower-->
            <div class="m-0">
              <span class="fw-semibold fs-6 text-gray-400">Total já pago por clientes</span>
            </div>
            <!--end::Follower-->
          </div>
          <!--end::Section-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Card widget 2-->
    </div>
    <div class="col-12 col-lg-3">
      <!--begin::Card widget 2-->
      <div class="card h-lg-100">
        <!--begin::Body-->
        <div class="card-body d-flex justify-content-center align-items-start flex-column">
          <!--begin::Section-->
          <div class="d-flex flex-column my-7">
            <!--begin::Number-->
            <span class="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{{ data.commission_amount / 100 | formatMoney }}</span>
            <!--end::Number-->
            <!--begin::Follower-->
            <div class="m-0">
              <span class="fw-semibold fs-6 text-gray-400">Total de comissão</span>
            </div>
            <!--end::Follower-->
          </div>
          <!--end::Section-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Card widget 2-->
    </div>
    <div class="col-12 col-lg-3">
      <!--begin::Card widget 2-->
      <div class="card h-lg-100">
        <!--begin::Body-->
        <div class="card-body d-flex justify-content-between align-items-start flex-column">
          <!--begin::Section-->
          <div class="d-flex flex-column my-7">
            <!--begin::Number-->
            <span class="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{{ data.commission_available_amount / 100 | formatMoney }}</span>
            <!--end::Number-->
            <!--begin::Follower-->
            <div class="m-0">
              <span class="fw-semibold fs-6 text-gray-400">Comissão disponível para receber</span>
            </div>
            <!--end::Follower-->
          </div>
          <!--end::Section-->
          <!--begin::Section-->
          <div class="d-flex flex-column my-5">
            <!--begin::Number-->
            <span class="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{{ data.commission_paid_amount / 100 | formatMoney }}</span>
            <!--end::Number-->
            <!--begin::Follower-->
            <div class="m-0">
              <span class="fw-semibold fs-6 text-gray-400">Comissão paga</span>
            </div>
            <!--end::Follower-->
          </div>
          <!--end::Section-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Card widget 2-->
    </div>
  </div>
</template>

<script>

export default {
  name: 'SalesSummary',
  props: ['data'],
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  }
}
</script>
